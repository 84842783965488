import React, {useEffect} from "react"
import { FaUser, FaStoreAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card/card"
import {selectCategory} from "../redux/registration/registrationAction"
import {getCategories} from "../redux/api/apiAction"

const MemberPage = () => {
    const dispatch = useDispatch()
    const {categories} = useSelector(({apiReducer}) => apiReducer)

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    const onClick = (nonMember = true) => {
        dispatch(selectCategory(categories, nonMember ? "Delegate" : "UNION Member"))
    }

    return (
        <Layout>
            <SEO title="Registration" />
        <div className={"card-list"}>
                <Card title={"Non membre"} link={"non-member-single"} onClick={() => onClick()}/>
                <Card title={"Member de l\'ordre"} link={"union-member-single"} onClick={() => onClick(false)}/>
            </div>
        </Layout>
    )
}

export default MemberPage
